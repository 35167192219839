<template>
  <div v-show="editMode">
    <div id="accordion-collapse" data-accordion="collapse">
      <h2>
        <button type="button" @click="accordionData[0].open = !accordionData[0].open"
          class="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0 border-gray-200 rounded-t-xl focus:ring-2 focus:ring-gray-200 hover:bg-gray-100"
          data-accordion-target="#accordion-collapse-body-1" aria-expanded="true">
          <span class="font-bold">Agent Info</span>
          <svg v-if="accordionData[0].open" data-accordion-icon class="w-6 h-6 rotate-180 shrink-0" fill="currentColor"
            viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"></path>
          </svg>
          <svg v-else data-accordion-icon class="w-6 h-6 shrink-0" fill="currentColor" viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"></path>
          </svg>
        </button>
      </h2>
      <div :class="accordionData[0].open ? '' : 'hidden'">
        <div class="p-5 font-light border border-b-0 border-gray-200  ">
          <!-- BEGIN: Creation date -->
          <div class="input-form mt-4" :class="{ 'has-error': isInvalid('first_name') }">
            <label for="creation_date" class="text-gray-600 font-bold">Creation Date</label>
            <input v-model="formData.created_at_formatted" disabled id="creation_date" type="text" class="input-field  w-full" name="creation_date">
          </div>
          <!-- END: Creation date -->

          <!-- BEGIN: Roles -->
          <div class="input-form mt-4" :class="{ 'has-error': isInvalid('agent_roles_ids') }">
            <label for="leader_ids" class="text-gray-600 font-bold">Agent Roles</label>
            <el-select v-model="formData.agent_roles_ids" id="agent_roles_ids" class="input-field w-full" multiple clearable>
              <el-option v-for="(role, i) in assignableRoles" :key="role.id" :label="role.name" :value="role.id" />
            </el-select>
            <div v-if="isInvalid('agent_roles_ids')" class="error-message mt-1">{{ errorMessage('agent_roles_ids') }}</div>
          </div>
          <!-- END: Roles -->

          <!-- BEGIN: Leader -->
          <div class="input-form mt-4" :class="{ 'has-error': isInvalid('leader_ids') }">
            <label for="leader_ids" class="text-gray-600 font-bold">Direct Upline</label>
            <el-select v-model="formData.leader_ids" id="leader_ids" class="input-field w-full" multiple clearable filterable>
              <el-option v-for="(leader, i) in possibleLeaders" :key="leader.id" :label="`${leader.last_name}, ${leader.first_name}`" :value="leader.id" />
            </el-select>
            <div v-if="isInvalid('leader_ids')" class="error-message mt-1">{{ errorMessage('leader_ids') }}</div>
          </div>
          <!-- END: Leader -->

          <!-- BEGIN: First name -->
          <div class="input-form mt-4" :class="{ 'has-error': isInvalid('first_name') }">
            <label for="first_name" class="text-gray-600 font-bold">First Name</label>
            <input v-model="first_name" id="first_name" type="text" class="input-field  w-full" name="first_name">
            <div v-if="isInvalid('first_name')" class="error-message mt-1">{{
                errorMessage('first_name')
            }}
            </div>
          </div>
          <!-- END: First Name -->

          <!-- BEGIN: Last name -->
          <div class="input-form mt-4" :class="{ 'has-error': isInvalid('last_name') }">
            <label for="last_name" class="text-gray-600 font-bold">Last Name</label>
            <input v-model="last_name" id="last_name" type="text" class="input-field  w-full" name="last_name">
            <div v-if="isInvalid('last_name')" class="error-message mt-1">{{ errorMessage('last_name') }}</div>
          </div>
          <!-- END: Last Name -->

          <!-- BEGIN: Title -->
          <div class="input-form mt-4" :class="{ 'has-error': isInvalid('title') }">
            <label for="title" class="text-gray-600 font-bold">Title</label>
            <input v-model="title" id="title" type="text" class="input-field  w-full" name="title">
            <div v-if="isInvalid('title')" class="error-message mt-1">{{ errorMessage('title') }}</div>
          </div>
          <!-- END: Title -->

          <!-- BEGIN: Address -->
          <div class="input-form mt-4" :class="{ 'has-error': isInvalid('address') }">
            <label for="address" class="text-gray-600 font-bold">Address</label>
            <input v-model="address" id="address" type="text" class="input-field  w-full" name="address">
            <div v-if="isInvalid('address')" class="error-message mt-1">{{ errorMessage('address') }}</div>
          </div>
          <!-- END: Address -->

          <!-- BEGIN: City -->
          <div class="input-form mt-4" :class="{ 'has-error': isInvalid('city') }">
            <label for="city" class="text-gray-600 font-bold">City</label>
            <input v-model="city" id="city" type="text" class="input-field  w-full" name="city">
            <div v-if="isInvalid('city')" class="error-message mt-1">{{ errorMessage('city') }}</div>
          </div>
          <!-- END: City -->

          <!-- BEGIN: State -->
          <div class="input-form mt-4" :class="{ 'has-error': isInvalid('state_id') }">
            <label for="address" class="text-gray-600 font-bold">State</label>
            <select v-model="state_id" data-placeholder="Select state" class="input-field  w-full" id="state_id"
              name="state_id">
              <option v-for="(state) in states" :key="state.id" :value="state.id">{{ state.name }}</option>
            </select>
            <div v-if="isInvalid('state_id')" class="error-message mt-1">{{ errorMessage('state_id') }}</div>
          </div>
          <!-- END: State -->

          <!-- BEGIN: Zip -->
          <div class="input-form mt-4" :class="{ 'has-error': isInvalid('zip') }">
            <label for="zip" class="text-gray-600 font-bold">Zip</label>
            <input v-model="zip" id="zip" type="text" class="input-field  w-full" name="zip">
            <div v-if="isInvalid('zip')" class="error-message mt-1">{{ errorMessage('zip') }}</div>
          </div>
          <!-- END: Zip -->

          <!-- BEGIN: Email -->
          <div class="input-form mt-4" :class="{ 'has-error': isInvalid('email') }">
            <label for="address" class="text-gray-600 font-bold">Email</label>
            <input v-model="email" id="email" type="email" class="input-field  w-full" name="email">
            <div v-if="isInvalid('email')" class="error-message mt-1">{{ errorMessage('email') }}</div>
          </div>
          <!-- END: Email -->

          <!-- BEGIN: Password -->
          <div class="input-form mt-4" :class="{ 'has-error': isInvalid('password') }">
            <label for="password" class="text-gray-600 font-bold">Password</label>
            <input v-model="password" id="password" type="password" class="input-field  w-full" name="password" readonly onfocus="this.removeAttribute('readonly');">
            <div v-if="isInvalid('password')" class="error-message mt-1">{{ errorMessage('password') }}</div>
          </div>
          <!-- END: Password -->

          <!-- BEGIN: Office Phone -->
          <div class="input-form mt-4" :class="{ 'has-error': isInvalid('office_phone') }">
            <label for="address" class="text-gray-600 font-bold">Office Phone</label>
            <input v-maska="'(###) ###-####'" v-model="office_phone" id="office_phone" type="text"
              class="input-field  w-full" name="office_phone">
            <div v-if="isInvalid('office_phone')" class="error-message mt-1">{{
                errorMessage('office_phone')
            }}
            </div>
          </div>
          <!-- END: Office Phone -->

          <!-- BEGIN: Mobile Phone -->
          <div class="input-form mt-4" :class="{ 'has-error': isInvalid('mobile_phone') }">
            <label for="mobile_phone" class="text-gray-600 font-bold">Mobile Phone</label>
            <input v-maska="'(###) ###-####'" v-model="mobile_phone" id="mobile_phone" type="text"
              class="input-field  w-full" name="mobile_phone">
            <div v-if="isInvalid('mobile_phone')" class="error-message mt-1">{{
                errorMessage('mobile_phone')
            }}
            </div>
          </div>
          <!-- END: Mobile Phone -->

          <!-- BEGIN: Timezone -->
          <div class="input-form mt-4" :class="{ 'has-error': isInvalid('time_zone') }">
            <label for="time_zone" class="text-gray-600 font-bold">Time zone</label>
            <select v-model="time_zone" data-placeholder="Select source" class="input-field  w-full" id="timezone"
              name="timezone">
              <option v-for="(time_zone) in timezones" :key="time_zone.value" :value="time_zone.value">{{
                  time_zone.label
              }}
              </option>
            </select>
            <div v-if="isInvalid('time_zone')" class="error-message mt-1">{{ errorMessage('time_zone') }}</div>
          </div>
          <!-- END: Timezone -->

          <!-- BEGIN: Billing Profile -->
          <!-- <div class="input-form mt-4" :class="{ 'has-error': isInvalid('billing_profile_id') }">
            <label for="state_id" class="text-gray-600 font-bold">Billing Profile</label>
            <select v-model="billing_profile_id" data-placeholder="Select billing profile" class="input-field  w-full"
              id="billing_profile_id" name="profile">
              <option value="" selected>—</option>
              <option value="0">Agent Pays</option>
              <option v-for="(card) in billingProfiles" :key="card.id" :value="card.id">
                {{ `${card.full_name} Card ending in ${card.last_four_card_digits}` }}
              </option>
            </select>
            <div v-if="isInvalid('billing_profile_id')" class="error-message mt-1">
              {{ errorMessage('billing_profile_id') }}
            </div>
          </div> -->
          <!-- END: Billing Profile -->

          <!-- BEGIN: Force Agent Pay    -->
          <div class="input-form mt-4" :class="{ 'has-error': isInvalid('force_agent_pay') }">
            <label for="force_agent_pay" class="text-gray-600 -mt-1 font-bold"> Force Agent Pay </label>
            <div class="input-group">
              <select v-model="force_agent_pay" id="force_agent_pay" name="force_agent_pay" class="input-field">
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </div>
            <div class=" text-slate-400 mt-1">
              Setting this option forces the agent to pay for the subscription,
              even if billing option is set as Agency Pay
            </div>
            <div v-if="isInvalid('force_agent_pay')" class="error-message mt-1">
              {{ errorMessage('force_agent_pay') }}
            </div>
          </div>
          <!-- END: Force Agent Pay -->
        </div>
      </div>


      <h2>
        <button type="button" @click="accordionData[1].open = !accordionData[1].open"
          class="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0 border-gray-200 focus:ring-2 focus:ring-gray-200    hover:bg-gray-100 "
          data-accordion-target="#accordion-collapse-body-2" aria-expanded="false">
          <span class="font-bold">SMS Settings</span>
          <svg v-if="accordionData[1].open" data-accordion-icon class="w-6 h-6 rotate-180 shrink-0" fill="currentColor"
            viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"></path>
          </svg>
          <svg v-else data-accordion-icon class="w-6 h-6 shrink-0" fill="currentColor" viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"></path>
          </svg>
        </button>
      </h2>
      <div :class="accordionData[1].open ? '' : 'hidden'">
        <div class="p-5 font-light border border-b-0 border-gray-200 ">
          <div v-if="agentHasOwnTwilioAccountNumber">This agent has their own Twilio account set up.</div>

          <!-- BEGIN: Twilio Number -->
          <div v-if="agentHasOwnTwilioAccountNumber" class="input-form mt-4">
            <label for="twilio_number" class="text-gray-600 font-bold">Agent Owned Twilio Number</label>
            <input :value="agent_info.agent_owned_twilio_number?.phone_number" id="agent_owned_number" type="text" class="input-field  w-full"
              name="twilio_number" readonly>
            <div v-if="isInvalid('twilio_number')" class="error-message mt-1">{{
                errorMessage('twilio_number')
              }}
            </div>
          </div>
          <!-- END: Twilio Number -->

          <el-popconfirm
	            width="220"
	            title="Are you sure?"
	            confirm-button-text="Yes"
	            cancel-button-text="No"
              v-if="!agentHasOwnTwilioAccountNumber"
	            @confirm="provisionPhoneNumber">
            <template #reference>
                <button class="btn-secondary py-1 text-sm">Provision new number</button>
            </template>
          </el-popconfirm>


          <!-- BEGIN: Twilio Number -->
          <div class="input-form mt-4" :class="{ 'has-error': isInvalid('twilio_number') }">
            <label for="twilio_number" class="text-gray-600 font-bold">TopBroker Twilio Number</label>
            <select :disabled="agentHasOwnTwilioAccountNumber" v-model="twilio_phone_number_id" data-placeholder="Select source" class="input-field w-full" id="sms_number" name="sms_number">
                <option :value="twilioPhoneNumber.id" v-for="twilioPhoneNumber in availableTwilioPhoneNumbers">
                    {{ twilioPhoneNumber.phone_number}}
                </option>
            </select>
            <div v-if="isInvalid('twilio_number')" class="error-message mt-1">{{
                errorMessage('twilio_number')
            }}
            </div>
          </div>
          <!-- END: Twilio Number -->

          <!-- BEGIN: Twilio Agency Name Override -->
          <div class="input-form mt-4" :class="{ 'has-error': isInvalid('sms_agency_name_override') }">
            <label for="sms_agency_name_override" class="text-gray-600 font-bold">Twilio Agency Name Override</label>
            <input v-model="sms_agency_name_override" id="sms_agency_name_override" type="text" class="input-field  w-full"
              name="sms_agency_name_override">
            <div v-if="isInvalid('sms_agency_name_override')" class="error-message mt-1">
              {{ errorMessage('sms_agency_name_override') }}
            </div>
          </div>
          <!-- END: Twilio Agency Name Override -->

            <!-- BEGIN: Click To Call -->
            <div class="input-form mt-4" :class="{ 'has-error': isInvalid('clicktocall') }">
                <label for="clicktocall" class="text-gray-600 font-bold">Click To Call</label>
                <select v-model="clicktocall" class="input-field  w-full" id="clicktocall" name="clicktocall">
                    <option value="">None</option>
                    <option value="TBComm">TBComm</option>
                    <option value="3CX">3CX</option>
                    <option value="Skype">Skype</option>
                    <option value="Google">Google</option>
                </select>
                <div v-if="isInvalid('clicktocall')" class="error-message mt-1">
                    {{ errorMessage('clicktocall') }}
                </div>
            </div>
            <!-- END: Click To Call -->

          <!-- BEGIN: Alternate SMS Provider -->
          <!--<div class="input-form mt-4" :class="{ 'has-error': isInvalid('alternate_sms_acct_id') }">
            <label for="alternate_sms_acct_id" class="text-gray-600 font-bold">Alternate SMS Provider (TODO)</label>
            <select v-model="alternate_sms_acct_id" class="input-field  w-full" id="alternate_sms_acct_id"
              name="alternate_sms_acct_id">
              <option value="0">Disabled</option>
            </select>
            <div v-if="isInvalid('alternate_sms_acct_id')" class="error-message mt-1">
              {{ errorMessage('alternate_sms_acct_id') }}
            </div>
          </div>-->
          <!-- END: Alternate SMS Provider -->

          <!-- BEGIN: Alternate SMS DID -->
          <!--<div class="input-form mt-4" :class="{ 'has-error': isInvalid('alternate_sms_did') }">
            <label for="alternate_sms_did" class="text-gray-600 font-bold">Alternate SMS DID (TODO)</label>
            <input v-model="alternate_sms_did" id="alternate_sms_did" type="text" class="input-field  w-full"
              name="alternate_sms_did">
            <div v-if="isInvalid('alternate_sms_did')" class="error-message mt-1">
              {{ errorMessage('alternate_sms_did') }}
            </div>
          </div>-->
        </div>
      </div>


      <h2>
        <button type="button" @click="accordionData[2].open = !accordionData[2].open"
          class="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-gray-200 focus:ring-2 focus:ring-gray-200    hover:bg-gray-100 "
          data-accordion-target="#accordion-collapse-body-3" aria-expanded="false">
          <span class="font-bold">Other Settings</span>
          <svg v-if="accordionData[2].open" data-accordion-icon class="w-6 h-6 rotate-180 shrink-0" fill="currentColor"
            viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"></path>
          </svg>
          <svg v-else data-accordion-icon class="w-6 h-6 shrink-0" fill="currentColor" viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"></path>
          </svg>
        </button>
      </h2>
      <div :class="accordionData[2].open ? '' : 'hidden'">
        <div class="p-5 font-light border border-t-0 border-gray-200 ">
          <!-- BEGIN: FFM User -->
          <div class="input-form mt-4" :class="{ 'has-error': isInvalid('ffm_user') }">
            <label for="ffm_user" class="text-gray-600 font-bold">FFM User</label>
            <input v-model="ffm_user" id="ffm_user" type="text" class="input-field  w-full" name="ffm_user">
            <div v-if="isInvalid('ffm_user')" class="error-message mt-1">{{ errorMessage('ffm_user') }}</div>
          </div>
          <!-- END: FFM User -->

          <!-- BEGIN: FFM Pass -->
          <div class="input-form mt-4" :class="{ 'has-error': isInvalid('ffm_pass') }">
            <label for="address" class="text-gray-600 font-bold">FFM Pass</label>
            <input v-model="ffm_pass" id="ffm_pass" type="text" class="input-field  w-full" name="ffm_pass">
            <div v-if="isInvalid('ffm_pass')" class="error-message mt-1">{{ errorMessage('ffm_pass') }}</div>
          </div>
          <!-- END: FFM Pass -->

          <!-- BEGIN: Recruiter Admin -->
          <div class="input-form mt-4" :class="{ 'has-error': isInvalid('is_recruiter_admin') }">
            <label for="is_recruiter_admin" class="text-gray-600 font-bold">Recruiter Admin</label>
            <select v-model="is_recruiter_admin" class="input-field  w-full" id="is_recruiter_admin"
              name="is_recruiter_admin">
              <option value="0">No</option>
              <option value="1">Yes</option>
            </select>
            <div v-if="isInvalid('is_recruiter_admin')" class="error-message mt-1">
              {{ errorMessage('is_recruiter_admin') }}
            </div>
          </div>
          <!-- END: Recruiter Admin -->

          <!-- BEGIN: Recruiter Manager -->
          <div class="input-form mt-4" :class="{ 'has-error': isInvalid('is_recruiter_manager') }">
            <label for="is_recruiter_manager" class="text-gray-600 font-bold">Recruiter Manager</label>
            <select v-model="is_recruiter_manager" class="input-field  w-full" id="is_recruiter_manager"
              name="is_recruiter_manager">
              <option value="0">No</option>
              <option value="1">Yes</option>
            </select>
            <div v-if="isInvalid('is_recruiter_manager')" class="error-message mt-1">
              {{ errorMessage('is_recruiter_manager') }}
            </div>
          </div>
          <!-- END: Recruiter Manager -->

          <!-- BEGIN: Working Hours -->
          <div class="input-form mt-4" :class="{ 'has-error': isInvalid('lock_working_hours') }">
            <label for="lock_working_hours" class="text-gray-600 font-bold">Working Hours</label>
            <select v-model="lock_working_hours" class="input-field  w-full" id="lock_working_hours"
              name="lock_working_hours">
              <option value="0">Allow agent to modify hours</option>
              <option value="1">Do NOT allow agent to modify hours</option>
            </select>
            <div v-if="isInvalid('lock_working_hours')" class="error-message mt-1">
              {{ errorMessage('lock_working_hours') }}
            </div>
          </div>
          <!-- END: Working Hours -->

          <!-- BEGIN: Allow Search Exports -->
          <div class="input-form mt-4" :class="{ 'has-error': isInvalid('allow_search_export') }">
            <label for="allow_search_export" class="text-gray-600 font-bold">Allow Search Exports</label>
            <select v-model="allow_search_export" class="input-field  w-full" id="allow_search_export"
              name="allow_search_export">
              <option value="0">No</option>
              <option value="1">Yes</option>
            </select>
            <div class=" text-slate-400 mt-1">Allows agents to download report to a csv file.</div>
            <div v-if="isInvalid('allow_search_export')" class="error-message mt-1">
              {{ errorMessage('allow_search_export') }}
            </div>
          </div>
          <!-- END: Allow Search Exports -->

          <!-- BEGIN: Allow Create users -->
          <div class="input-form mt-4" :class="{ 'has-error': isInvalid('allow_create_users') }">
            <label for="allow_create_users" class="text-gray-600 font-bold">Allow Create users</label>
            <select v-model="allow_create_users" class="input-field  w-full" id="allow_create_users"
              name="allow_create_users">
              <option value="0">No</option>
              <option value="1">Yes</option>
            </select>
            <div v-if="isInvalid('allow_create_users')" class="error-message mt-1">
              {{ errorMessage('allow_create_users') }}
            </div>
          </div>
          <!-- END: Allow Create users -->

          <!-- BEGIN: Allow To Search Agency -->
          <div class="input-form mt-4" :class="{ 'has-error': isInvalid('allow_search_agency') }">
            <label for="allow_search_agency" class="text-gray-600">Allow To Search Agency</label>
            <select v-model="allow_search_agency" class="input-field  w-full" id="allow_search_agency"
              name="allow_search_agency">
              <option value="0">No</option>
              <option value="1">Yes</option>
            </select>
            <div v-if="isInvalid('allow_search_agency')" class="error-message mt-1">
              {{ errorMessage('allow_search_agency') }}
            </div>
          </div>
          <!-- END: Allow To Search Agency -->

          <!-- BEGIN: BDC Rep? -->
          <div class="input-form mt-4" :class="{ 'has-error': isInvalid('is_bdc') }">
            <label for="is_bdc" class="text-gray-600 font-bold">BDC Rep?</label>
            <select v-model="is_bdc" class="input-field  w-full" id="is_bdc" name="is_bdc">
              <option value="0">No</option>
              <option value="1">Yes</option>
            </select>
            <div v-if="isInvalid('is_bdc')" class="error-message mt-1">{{ errorMessage('is_bdc') }}</div>
          </div>
          <!-- END: BDC Rep? -->

          <!-- BEGIN: Can Accept BDC Appt? -->
          <div class="input-form mt-4" :class="{ 'has-error': isInvalid('accept_bdc_appt') }">
            <label for="accept_bdc_appt" class="text-gray-600 font-bold">Can Accept BDC Appt?</label>
            <select v-model="accept_bdc_appt" class="input-field  w-full" id="accept_bdc_appt" name="accept_bdc_appt">
              <option value="0">No</option>
              <option value="1">Yes</option>
            </select>
            <div v-if="isInvalid('accept_bdc_appt')" class="error-message mt-1">
              {{ errorMessage('accept_bdc_appt') }}
            </div>
          </div>
          <!-- END: Can Accept BDC Appt? -->

          <!-- BEGIN: Allow to delete notes -->
          <div class="input-form mt-4" :class="{ 'has-error': isInvalid('allow_delete_notes') }">
            <label for="allow_delete_notes" class="text-gray-600 font-bold">Allow to delete notes</label>
            <select v-model="allow_delete_notes" class="input-field  w-full" id="allow_delete_notes"
              name="allow_delete_notes">
              <option value="0">No</option>
              <option value="1">Yes</option>
            </select>
            <div v-if="isInvalid('allow_delete_notes')" class="error-message mt-1">
              {{ errorMessage('allow_delete_notes') }}
            </div>
          </div>
          <!-- END: Allow to delete notes -->

          <!-- BEGIN: Allow agent to create Round Robin Groups -->
          <div class="input-form mt-4" :class="{ 'has-error': isInvalid('allow_create_round_robin_groups') }">
            <label for="allow_create_round_robin_groups" class="text-gray-600 font-bold">Allow agent to create Round Robin
              Groups</label>
            <select v-model="allow_create_round_robin_groups" class="input-field  w-full"
              id="allow_create_round_robin_groups" name="allow_create_round_robin_groups">
              <option value="0">No</option>
              <option value="1">Yes</option>
            </select>
            <div v-if="isInvalid('allow_create_round_robin_groups')" class="error-message mt-1">
              {{ errorMessage('allow_create_round_robin_groups') }}
            </div>
          </div>
          <!-- END: Allow agent to create Round Robin Groups -->

          <!-- BEGIN: Allow bad phone credits  -->
          <div class="input-form mt-4" :class="{ 'has-error': isInvalid('allow_bad_phone_credit') }">
            <label for="allow_bad_phone_credit" class="text-gray-600 font-bold"> Allow bad phone credits </label>
            <select v-model="allow_bad_phone_credit" class="input-field  w-full" id="allow_bad_phone_credit"
              name="allow_bad_phone_credit">
              <option :value="0">No</option>
              <option :value="1">Yes</option>
            </select>
            <div class=" text-slate-400 mt-1">If selected and the lead source allows bad credits, agent will receive
              agency
              bucks refund on lead
            </div>
            <div v-if="isInvalid('allow_bad_phone_credit')" class="error-message mt-1">
              {{ errorMessage('allow_bad_phone_credit') }}
            </div>
          </div>
          <!-- END: Allow bad phone credits  -->

          <!-- BEGIN: Allow already purchased credits -->
          <div class="input-form mt-4" :class="{ 'has-error': isInvalid('allow_already_purchased_credit') }">
            <label for="allow_already_purchased_credit" class="text-gray-600 font-bold">Allow already purchased
              credits</label>
            <select v-model="allow_already_purchased_credit" class="input-field  w-full"
              id="allow_already_purchased_credit" name="allow_already_purchased_credit">
              <option value="0">No</option>
              <option value="1">Yes</option>
            </select>
            <div class=" text-slate-400 mt-1">If selected and the lead source allows already purchased credit, agent
              will
              receive agency bucks refund on lead
            </div>
            <div v-if="isInvalid('allow_already_purchased_credit')" class="error-message mt-1">
              {{ errorMessage('allow_already_purchased_credit') }}
            </div>
          </div>
          <!-- END: Allow already purchased credits -->

          <!-- BEGIN: Allow personal lead bucket text drip campaigns   -->
          <div class="input-form mt-4" :class="{ 'has-error': isInvalid('allow_bucket_responder') }">
            <label for="allow_bucket_responder" class="text-gray-600 font-bold"> Allow personal lead bucket text drip
              campaigns </label>
            <select v-model="allow_bucket_responder" class="input-field  w-full" id="allow_bucket_responder"
              name="allow_bucket_responder">
              <option value="0">No</option>
              <option value="1">Yes</option>
            </select>
            <div class=" text-slate-400 mt-1">If selected this agent can view and manage personal lead bucket text drip
              campaigns
            </div>
            <div v-if="isInvalid('allow_bucket_responder')" class="error-message mt-1">
              {{ errorMessage('allow_bucket_responder') }}
            </div>
          </div>
          <!-- END: Allow personal lead bucket text drip campaigns   -->

          <!-- BEGIN: Allow agent to go negative credits    -->
          <div class="input-form mt-4" :class="{ 'has-error': isInvalid('allow_negative_funds') }">
            <label for="allow_negative_funds" class="text-gray-600 -mt-1 font-bold"> Allow agent to go negative
              credits </label>
            <div class="input-group">
              <input v-model="allow_negative_funds" id="allow_negative_funds" type="text" class="input-field  w-full"
                name="allow_negative_funds">
            </div>
            <div class=" text-slate-400 mt-1">Setting this option allows this agent to have a negative balance up to the
              value
              selected
            </div>
            <div v-if="isInvalid('allow_negative_funds')" class="error-message mt-1">
              {{ errorMessage('allow_negative_funds') }}
            </div>
          </div>
          <!-- END: Allow agent to go negative dollars    -->

          <!-- BEGIN: Grace Pulls Override -->
          <div class="input-form mt-4" :class="{ 'has-error': isInvalid('grace_pull_override') }">
            <label for="grace_pull_override" class="text-gray-600 font-bold">Grace Pulls</label>
            <select v-model="grace_pull_override" class="input-field  w-full"
                    id="grace_pull_override" name="grace_pull_override">
              <option value="0">Active</option>
              <option value="1">Override - Inactive</option>
            </select>
            <div v-if="isInvalid('grace_pull_override')" class="error-message mt-1">
              {{ errorMessage('grace_pull_override') }}
            </div>
          </div>
          <!-- END: Grace Pulls Override -->
        </div>
      </div>

    </div>

    <!-- Update action, Notification -->
    <div v-show="notify" class="alert alert-primary-soft alert-dismissible show flex items-center mt-5 py-2"
      role="alert">
      <span>{{ notificationMessage }}</span>
      <button @click="notify = false;" type="button" class="btn-close" aria-label="Close">
        <i data-lucide="x" class="w-4 h-4"></i>
      </button>
    </div>

    <div class="mt-5">
      <button @click.prevent="submit" type="submit" class="btn btn-primary w-24"
        :class="{ 'pointer-events-none': loading }">
        <LoadingSpinner v-if="loading"></LoadingSpinner>
        <span v-else>Update</span>
      </button>
    </div>
  </div>

  <!-- Read Only Mode -->
  <agent-info-read-only
      v-if="!editMode" @show-edit-mode="handleShowEditMode"
      :agent_info="agent_info"
      :agentHasOwnTwilioAccountNumber="agentHasOwnTwilioAccountNumber"
  ></agent-info-read-only>
</template>

<script>
import LoadingSpinner from "@/Components/LoadingSpinner.vue";
import serverValidationErrorsMixin from "../mixins/serverValidationErrorsMixin";
import helpers from "../../helper";
import AgentInfoReadOnly from "./AgentInfoReadOnly.vue";
import _ from "lodash";
import {ElOption, ElPopconfirm, ElSelect} from "element-plus";
import {Inertia} from "@inertiajs/inertia";


export default {
  name: "AgentInfo",

  mixins: [serverValidationErrorsMixin],

  components: {
    ElPopconfirm,
    LoadingSpinner,
    AgentInfoReadOnly,
    ElSelect,
    ElOption,
  },

  props: {
    agent_info: {
      type: Object,
      required: true,
    },
    assignableRoles: Object,
    states: {
      type: Array,
      default: () => [],
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    possibleLeaders: {
      type: Array,
      default: () => [],
    },
    timezones: {
      type: Array,
      default: () => [],
    },
    buckets: {
      type: Array,
      default: () => [],
    },
    availableTwilioPhoneNumbers: {
      type: Array,
      default: () => [],
    },
    agentHasOwnTwilioAccountNumber: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:agent_info', 'show-edit-mode'],

  data() {
    return {
      loading: false,
      notify: false,
      notificationMessage: 'Agent updated',
      formData: _.cloneDeep(this.agent_info),
      accordionData: [
        { key: "agent_info", open: true },
        { key: "sms_settings", open: false },
        { key: "other_settings", open: false },
      ],
      activeAccordion: "agent_info"
    }
  },

  computed: {
    leader_ids: {
      get() {
        return this.formData.leader_ids;
      },
      set(value) {
        this.formData.leader_ids = value;
      }
    },
    first_name: {
      get() {
        return this.formData.first_name;
      },
      set(value) {
        this.formData.first_name = value;
      }
    },
    last_name: {
      get() {
        return this.formData.last_name;
      },
      set(value) {
        this.formData.last_name = value;
      }
    },
    title: {
      get() {
        return this.formData.title;
      },
      set(value) {
        this.formData.title = value;
      }
    },
    address: {
      get() {
        return this.formData.address;
      },
      set(value) {
        this.formData.address = value;
      }
    },
    city: {
      get() {
        return this.formData.city;
      },
      set(value) {
        this.formData.city = value;
      }
    },
    state_id: {
      get() {
        return this.formData.state_id;
      },
      set(value) {
        this.formData.state_id = value;
      }
    },
    zip: {
      get() {
        return this.formData.zip;
      },
      set(value) {
        this.formData.zip = value;
      }
    },
    email: {
      get() {
        return this.formData.email;
      },
      set(value) {
        this.formData.email = value;
      }
    },
    password: {
      get() {
        return this.formData.password;
      },
      set(value) {
        this.formData.password = value;
      }
    },
    office_phone: {
      get() {
        return this.formData.office_phone;
      },
      set(value) {
        this.formData.office_phone = value;
      }
    },
    mobile_phone: {
      get() {
        return this.formData.mobile_phone;
      },
      set(value) {
        this.formData.mobile_phone = value;
      }
    },
    force_agent_pay: {
      get() {
        return this.formData.force_agent_pay;
      },
      set(value) {
        this.formData.force_agent_pay = value;
      }
    },
    time_zone: {
      get() {
        return this.formData.time_zone;
      },
      set(value) {
        this.formData.time_zone = value;
      }
    },
    twilio_number: {
      get() {
        return this.formData.twilio_number;
      }
    },
    twilio_phone_number_id: {
      get() {
          return this.formData.twilio_phone_number_id;
      },
      set(value) {
          return this.formData.twilio_phone_number_id = value;
      }
    },
    sms_agency_name_override: {
      get() {
        return this.formData.sms_agency_name_override;
      },
      set(value) {
        this.formData.sms_agency_name_override = value;
      }
    },
    clicktocall: {
      get() {
        return this.formData.clicktocall;
      },
      set(value) {
        this.formData.clicktocall = value;
      }
    },
    ffm_user: {
      get() {
        return this.formData.ffm_user;
      },
      set(value) {
        this.formData.ffm_user = value;
      }
    },
    ffm_pass: {
      get() {
        return this.formData.ffm_pass;
      },
      set(value) {
        this.formData.ffm_pass = value;
      }
    },
    is_recruiter_admin: {
      get() {
        return this.formData.is_recruiter_admin;
      },
      set(value) {
        this.formData.is_recruiter_admin = value;
      }
    },
    is_recruiter_manager: {
      get() {
        return this.formData.is_recruiter_manager;
      },
      set(value) {
        this.formData.is_recruiter_manager = value;
      }
    },
    grace_pull_override: {
      get() {
        return this.formData.grace_pull_override;
      },
      set(value) {
        this.formData.grace_pull_override = value;
      }
    },
    ssn: {
      get() {
        return this.formData.ssn;
      },
      set(value) {
        this.formData.ssn = value;
      }
    },
    allow_search_export: {
      get() {
        return this.formData.allow_search_export;
      },
      set(value) {
        this.formData.allow_search_export = value;
      }
    },
    allow_create_users: {
      get() {
        return this.formData.allow_create_users;
      },
      set(value) {
        this.formData.allow_create_users = value;
      }
    },
    allow_search_agency: {
      get() {
        return this.formData.allow_search_agency;
      },
      set(value) {
        this.formData.allow_search_agency = value;
      }
    },
    lock_working_hours: {
      get() {
        return this.formData.lock_working_hours;
      },
      set(value) {
        this.formData.lock_working_hours = value;
      }
    },
    is_bdc: {
      get() {
        return this.formData.is_bdc;
      },
      set(value) {
        this.formData.is_bdc = value;
      }
    },
    accept_bdc_appt: {
      get() {
        return this.formData.accept_bdc_appt;
      },
      set(value) {
        this.formData.accept_bdc_appt = value;
      }
    },
    alternate_sms_acct_id: {
      get() {
        return this.formData.alternate_sms_acct_id;
      },
      set(value) {
        this.formData.alternate_sms_acct_id = value;
      }
    },
    alternate_sms_did: {
      get() {
        return this.formData.alternate_sms_did;
      },
      set(value) {
        this.formData.alternate_sms_did = value;
      }
    },
    allow_delete_notes: {
      get() {
        return this.formData.allow_delete_notes;
      },
      set(value) {
        this.formData.allow_delete_notes = value;
      }
    },
    allow_create_round_robin_groups: {
      get() {
        return this.formData.allow_create_round_robin_groups;
      },
      set(value) {
        this.formData.allow_create_round_robin_groups = value;
      }
    },
    allow_bad_phone_credit: {
      get() {
        return this.formData.allow_bad_phone_credit;
      },
      set(value) {
        this.formData.allow_bad_phone_credit = value;
      }
    },
    allow_already_purchased_credit: {
      get() {
        return this.formData.allow_already_purchased_credit;
      },
      set(value) {
        this.formData.allow_already_purchased_credit = value;
      }
    },
    allow_bucket_responder: {
      get() {
        return this.formData.allow_bucket_responder;
      },
      set(value) {
        this.formData.allow_bucket_responder = value;
      }
    },
    allow_negative_funds: {
      get() {
        return this.formData.allow_negative_funds;
      },
      set(value) {
        this.formData.allow_negative_funds = value;
      }
    },
    hst_purchasing: {
      get() {
        return this.formData.hst_purchasing;
      },
      set(value) {
        this.formData.hst_purchasing = value;
      }
    },
    hst_sync: {
      get() {
        return this.formData.hst_sync;
      },
      set(value) {
        this.formData.hst_sync = value;
      }
    },
    hst_agent_id: {
      get() {
        return this.formData.hst_agent_id;
      },
      set(value) {
        this.formData.hst_agent_id = value;
      }
    },
  },

  methods: {
    submit(e) {
      if (this.loading) return;
      this.loading = true;

      setTimeout(() => {
        axios
          .put(`/agents/${this.agent_info.id}`, this.formData)
          .then((response) => {
            this.handleSuccessfulSubmission(response);
          })
          .catch((error) => {
            console.log(error, 'AgentInfo - submit()')
            this.handleErroredSubmission(error);
          })
          .finally(() => {
            this.loading = false;
          })
      }, 500)
    },

    handleSuccessfulSubmission(response) {
      this.$emit('update:agent_info', response.data);

      if (response.data && response.data.message) {
        this.showNotification(response.data.message);
      } else {
        this.showNotification();
      }

      this.errors = {};
    },

    handleErroredSubmission(error) {
      if (error.response && error.response.data && error.response.status === 422) {
        this.errors = error.response.data.errors;
        this.$nextTick(() => {
          helpers.scrollToClass('has-error');
        })
      } else if (error.response && error.response.data && error.response.data.message && error.response.status === 405) {
        Swal.fire({
          text: error.response.data.message,
          icon: 'error'
        })
      } else {
        Swal.fire({
          text: 'Unknown error occurred, please try again.',
          icon: 'error'
        })
      }
      console.log("AgentInfo - handleErroredSubmission", error.response);
    },

    showNotification(message = null) {
      $notify.success({
		      message: message ?? 'Agent Updated',
		      showClose: true,
      })
    },

    handleShowEditMode(payload) {
      this.$emit('show-edit-mode', payload);
    },

    provisionPhoneNumber() {
      if (this.loading) return;
      this.loading = true;
      setTimeout(() => {
        axios
          .post(route('agents.provision-new-twilio-number', this.agent_info.id))
          .then((response) => {
            Inertia.get(route('agents.show', this.agent_info.id), {}, {
              preserveScroll: true,
	            preserveState: false,
              only: ['agent_info', 'availableTwilioPhoneNumbers', 'errors'],
              onSuccess: () => {
                  this.showNotification('Phone number provisioned');
              }
            });
          })
          .catch((error) => {
              console.log(error, 'AgentInfo - submit()')
              this.handleErroredSubmission(error);
          })
          .finally(() => {
              this.loading = false;
          })
	    }, 500)
    }

  },
}
</script>
